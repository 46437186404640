<template>
  <div class="container">
    <nav-bar />
    <div class="header">
      <div class="title">
        绑定手机号
        <p>绑定手机号，让你的帐号更安全</p>
      </div>
      <div class="login-form">
        <phone-input :needCode="true" />
        <code-input :disCode.sync="disCode" :action="3" />
        <Submit 
          ref="submit"
          :disBtn="$checkBtn(form)" 
          @submit="toBind()" 
          btnStr="完成" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/navbar'
import PhoneInput from '@/components/phoneInput'
import CodeInput from '@/components/codeInput'
import Submit from '@/components/submit'
import { bindMobile } from '@/api/login'
import { Toast } from 'vant'
export default {
  name: 'bind',
  components: {
    NavBar,
    PhoneInput,
    CodeInput,
    Submit
  },
  data() {
    return {
      form: {
        mobile: '',
        code: ''
      },
      disCode: true
    }
  },
  methods: {
    toBind() {
      let obj = {
        social_code:{
          provider:'wechat',
          code:sessionStorage.getItem('code')
        },
        mobile_info:{ ...this.form},
      }
      bindMobile(obj).then((res) => {
        Toast('号码绑定成功!')
        this.$loginCb(res.data.access_token)
      }).catch(() => {
        this.$refs.submit.loading = false
      })
    }
  }
}
</script>
